exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-fi-tsx": () => import("./../../../src/pages/fi.tsx" /* webpackChunkName: "component---src-pages-fi-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-cardregistry-tsx": () => import("./../../../src/pages/projects/cardregistry.tsx" /* webpackChunkName: "component---src-pages-projects-cardregistry-tsx" */),
  "component---src-pages-projects-eventmanager-tsx": () => import("./../../../src/pages/projects/eventmanager.tsx" /* webpackChunkName: "component---src-pages-projects-eventmanager-tsx" */),
  "component---src-pages-projects-fundraising-tsx": () => import("./../../../src/pages/projects/fundraising.tsx" /* webpackChunkName: "component---src-pages-projects-fundraising-tsx" */),
  "component---src-pages-projects-kiltaunions-tsx": () => import("./../../../src/pages/projects/kiltaunions.tsx" /* webpackChunkName: "component---src-pages-projects-kiltaunions-tsx" */),
  "component---src-pages-projects-ktsms-tsx": () => import("./../../../src/pages/projects/ktsms.tsx" /* webpackChunkName: "component---src-pages-projects-ktsms-tsx" */),
  "component---src-pages-projects-ktvuecomponents-tsx": () => import("./../../../src/pages/projects/ktvuecomponents.tsx" /* webpackChunkName: "component---src-pages-projects-ktvuecomponents-tsx" */),
  "component---src-pages-projects-mycats-tsx": () => import("./../../../src/pages/projects/mycats.tsx" /* webpackChunkName: "component---src-pages-projects-mycats-tsx" */),
  "component---src-pages-projects-nogelainet-tsx": () => import("./../../../src/pages/projects/nogelainet.tsx" /* webpackChunkName: "component---src-pages-projects-nogelainet-tsx" */),
  "component---src-pages-projects-samivaan-tsx": () => import("./../../../src/pages/projects/samivaan.tsx" /* webpackChunkName: "component---src-pages-projects-samivaan-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-projects-ytj-tsx": () => import("./../../../src/pages/projects/ytj.tsx" /* webpackChunkName: "component---src-pages-projects-ytj-tsx" */)
}

